import { useState, useEffect } from 'react';

function appendScript(baseUrl, setScriptLoaded) {
  if (window.MktoForms2) return setScriptLoaded(true);

  const script = document.createElement('script');
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
}

function useMarketo({
  baseUrl,
  munchkinId,
  formId,
  onFormSubmit,
  onFormSuccess,
  onFormLoad
}) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded) {
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, (form) => {
        // Attach custom submission handlers
        setFormLoaded(true);
        if (onFormLoad) onFormLoad(true);
        if (onFormSubmit) {
          form.onSubmit(() => {
            console.log('inside marketo Form is being submitted...');
            onFormSubmit();
          });
        }

        if (onFormSuccess) {
          form.onSuccess((values, thankYouURL) => {
            console.log(' inside marketo Form submitted successfully!', values);
            onFormSuccess(values);
            return false; // Prevents default redirection to the Thank You page
          });
        }
      });
      return;
    }

    appendScript(baseUrl, setScriptLoaded);
  }, [
    scriptLoaded,
    baseUrl,
    munchkinId,
    formId,
    onFormSubmit,
    onFormSuccess,
    onFormLoad
  ]);

  return { scriptLoaded, formLoaded };
}

export default useMarketo;
