/*
 * COPYRIGHT (c) 2023
 * HONEYWELL INC.,
 * ALL RIGHTS RESERVED
 * Energy Calculator Component Library
 * Author: Askarali Mohammed Basheer
 * Created On: 01-07-2024
 */

import React, { useEffect, useRef, useState } from 'react';
import '../EnergyCalculator/EnergyCalculator.scss';
import EquipmentSummaryJson from '../../utils/EquipmentData.json';
import {
  Button,
  Icon,
  Input,
  Loader,
  Modal,
  Select,
  Table
} from '@scuf/common';
import {
  ITemplateEquipments,
  IUnOccupiedHours,
  IEnergyCalculatorProps,
  ITemplateEquipmentsList,
  ISelectValues
} from './EnergyCalculatorModels';
import { toPng, toJpeg } from 'html-to-image';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PDFDocument } from 'pdf-lib';
import '../../utils/NotoSans-ExtraBold-bold.js';
import '../../utils/HoneywellSansWeb-Extrabold-bold.js';
import '../../utils/HoneywellSansWeb-Medium-normal.js';
import { Chart } from '@scuf/charts';
import PDFJson from '../../utils/pdfs.json';
import useMarketo from '../../utils/useMarketo';
import { callback } from 'chart.js/dist/helpers/helpers.core';

function EnergyCalculator(props: IEnergyCalculatorProps) {
  const [currencyTypeSymbol, setCurrencyTypeSymbol] = useState<string>('$');
  const [currencyType, setCurrencyType] = useState<string>('USD');
  const [
    templateEquipmentsSummaryData,
    setTemplateEquipmentsSummaryData
  ] = useState<ITemplateEquipments[]>([]);
  const [jsonData, setJsonData] = useState<any[]>([]);
  const [selectedTemplateEquipment, setSelectedTemplateEquipment] = useState<
    string
  >('');
  const [equipmentCategoryOptions, setEquipmentCategoryOptions] = useState<
    any[]
  >([]);
  const [
    selectedTemplateEquipmentLabel,
    setSelectedTemplateEquipmentLabel
  ] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toggleResultResponseModal, setToggleResultResponseModal] = useState(
    false
  );
  const [toggleMarketoModal, setToggleMarketoModal] = useState(false);

  const [totalWorkingHours, setTotalWorkingHours] = useState<number>(0);
  const [totalWorkingHoursMonthly, setTotalWorkingHoursMonthly] = useState<
    number
  >(0);
  const [totalWorkingHoursYearly, setTotalWorkingHoursYearly] = useState<
    number
  >(0);
  const [totalEquipments, setTotalEquipments] = useState<number>(0);
  const [totalEquipmentsWithkWh, setTotalEquipmentsWithkWh] = useState<number>(
    0
  );

  const [outOfHoursWeeklyUse, setOutOfHoursWeeklyUse] = useState<string>('');
  const [afterScheduleWeeklyUse, setAfterScheduleWeeklyUse] = useState<string>(
    ''
  );
  const [outOfHoursMonthlyUse, setOutOfHoursMonthlyUse] = useState<string>('');
  const [afterScheduleMonthlyUse, setAfterScheduleMonthlyUse] = useState<
    string
  >('');
  const [outOfHoursYearlyUse, setOutOfHoursYearlyUse] = useState<string>('');
  const [afterScheduleYearlyUse, setAfterScheduleYearlyUse] = useState<string>(
    ''
  );

  const [outOfHoursWeeklyCost, setOutOfHoursWeeklyCost] = useState<string>('');
  const [afterScheduleWeeklyCost, setAfterScheduleWeeklyCost] = useState<
    string
  >('');
  const [outOfHoursMonthlyCost, setOutOfHoursMonthlyCost] = useState<string>(
    ''
  );
  const [afterScheduleMonthlyCost, setAfterScheduleMonthlyCost] = useState<
    string
  >('');
  const [outOfHoursYearlyCost, setOutOfHoursYearlyCost] = useState<string>('');
  const [afterScheduleYearlyCost, setAfterScheduleYearlyCost] = useState<
    string
  >('');

  const [energyCalculatorSummary, setEnergyCalculatorSummary] = useState<
    IEnergyCalculatorProps
  >({
    name: '',
    businessName: '',
    emailId: '',
    energyCost: 0
  });
  const [unOccupiedHoursData, setUnOccupiedHoursData] = useState<
    IUnOccupiedHours
  >({
    Monday: 12,
    Tuesday: 12,
    Wednesday: 12,
    Thursday: 12,
    Friday: 12,
    Saturday: 6,
    Sunday: 6
  });
  const currenctTypeOptions: ISelectValues[] = [
    { value: 'USD', text: '$ - U.S. Dollar' },
    { value: 'GBP', text: '£ - Pound' },
    { value: 'EUR', text: '€ - Euro' },
    { value: 'JPY', text: '¥ - Yen' },
    { value: 'CAD', text: 'C$ - Canadian Dollar' },
    { value: 'INR', text: '₹ - Rupee' }
  ];

  const [inputs, setInputs] = useState({
    baseUrl: '',
    munchkinId: '',
    formId: '',
    callback: ''
  });

  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(true);
  const [isMarketoFormSubmitted, setIsMarketoFormSubmitted] = useState<boolean>(
    false
  );

  const weeklyChartContainerRef = useRef(null);
  const monthlyChartContainerRef = useRef(null);
  const annualChartContainerRef = useRef(null);

  const DAILY_HOURS: number = 24;
  const KILO_WATT_CONVERSION: number = 1000;
  const WEEKLY_DAYS: number = 7;
  const MONTHLY_DAYS: number = 28;
  const YEARLY_DAYS: number = 364;
  const PRECISION_LENGTH: number = 2;
  const MONTHLY_WEEKS: number = 4;
  const YEARLY_WEEKS: number = 52;

  const loadJSONData = () => {
    const urlPath = window.location.pathname;
    if (urlPath.includes('us')) {
      const locationItem: any = EquipmentSummaryJson.filter(
        (item) => item.locationName === 'US'
      );
      setJsonData(locationItem[0].locationItems);
    } else if (urlPath.includes('uk')) {
      const locationItem: any = EquipmentSummaryJson.filter(
        (item) => item.locationName === 'UK'
      );
      setJsonData(locationItem[0].locationItems);
    } else if (urlPath.includes('eu')) {
      const locationItem: any = EquipmentSummaryJson.filter(
        (item) => item.locationName === 'EU'
      );
      setJsonData(locationItem[0].locationItems);
    } else if (urlPath.includes('meta')) {
      const locationItem: any = EquipmentSummaryJson.filter(
        (item) => item.locationName === 'META'
      );
      setJsonData(locationItem[0].locationItems);
    } else {
      const locationItem: any = EquipmentSummaryJson.filter(
        (item) => item.locationName === 'Default'
      );
      setJsonData(locationItem[0].locationItems);
    }
  };

  useEffect(() => {
    createEquipmentCategory();
    showResultsCharts();
    loadJSONData();
  }, []);

  useEffect(() => {
    const extractedCategories = jsonData.flatMap((template) =>
      template.templateEquipments.map(
        (equipment: {
          equipmentCategory: string;
          equipmentCategoryId: string;
        }) => ({
          text: equipment.equipmentCategory,
          value: equipment.equipmentCategoryId
        })
      )
    );
    const uniqueCategoriesMap = new Map();
    extractedCategories.forEach((category) => {
      if (!uniqueCategoriesMap.has(category.value)) {
        uniqueCategoriesMap.set(category.value, category);
      }
    });
    const uniqueCategories = Array.from(uniqueCategoriesMap.values());
    // uniqueCategories.push({
    //   text: 'Others',
    //   value: 'C99999'
    // });
    setEquipmentCategoryOptions(uniqueCategories);
  }, [jsonData]);

  useEffect(() => {
    showResultsCharts();
  }, [
    selectedTemplateEquipment,
    unOccupiedHoursData,
    energyCalculatorSummary.energyCost,
    templateEquipmentsSummaryData
  ]);

  const quickPopulationOptions: any = jsonData.map((item) => {
    const obj: ISelectValues = {
      value: '',
      text: ''
    };
    obj.text = item.templateName;
    obj.value = item.templateId;
    return obj;
  });
  quickPopulationOptions.sort((a: { text: number }, b: { text: number }) =>
    a.text < b.text ? -1 : 1
  );
  quickPopulationOptions.push({ text: 'Create my own', value: 'Others' });
  let tempArray: any = [];
  const equipmentsListOptionsArr: any = jsonData.map((item) => {
    tempArray = [...tempArray, ...item.templateEquipments];
  });
  const equipmentsListOptions: any[] = [
    ...new Map(
      tempArray.map((item: ITemplateEquipments) => [
        item.equipmentCategoryId,
        item
      ])
    ).values()
  ];
  equipmentsListOptions.push({
    equipmentId: '11158',
    equipmentMode: 'FullMode',
    equipmentName: 'Others',
    kwHUsage: 0,
    noOfDevices: 0
  });

  const getEquipmentsDetailsList = (categoryId: string) => {
    const selectedEquipment: any = jsonData.flatMap((template) =>
      template.templateEquipments.find(
        (equipment: { equipmentCategoryId: any }) =>
          equipment.equipmentCategoryId === categoryId
      )
    );
    if (selectedEquipment) {
      const equipmentNames = selectedEquipment.flatMap(
        (equipmentCategory: any) =>
          equipmentCategory?.equipmentList
            ? equipmentCategory?.equipmentList.map(
                (equipment: any) => equipment.equipmentName
              )
            : []
      );
      const uniqueNames: any = Array.from(new Set(equipmentNames)).map(
        (name) => ({
          text: name,
          value: name
        })
      );
      // const othersArr = [{ text: 'Others', value: 'Others' }];

      return uniqueNames;
    }
  };

  const updateEquipmentDetailsList = (
    equipmentCategoryVal: string,
    equipmentList: ITemplateEquipmentsList[]
  ) => {
    const tempVarArray: ITemplateEquipmentsList[] = equipmentList.map(
      (value: ITemplateEquipmentsList) => ({
        ...value,
        equipmentCategoryValue: equipmentCategoryVal,
        equipmentCategoryTextValue: equipmentCategoryOptions.find(
          (item) => item.value === equipmentCategoryVal
        ).text,
        equipmentListOptions: getEquipmentsDetailsList(equipmentCategoryVal)
      })
    );
    return tempVarArray;
  };

  const handleTemplateTypeChange = (data: string) => {
    setSelectedTemplateEquipment(data);
    if (data === 'Others') {
      const tempEquipmentList: ITemplateEquipmentsList = {
        equipmentId: '',
        equipmentName: '',
        noOfDevices: 0,
        kwHUsage: 0,
        equipmentMode: '',
        equipmentCategoryValue: '',
        equipmentListOptions: [],
        equipmentCategoryTextValue: ''
      };
      const newArr: ITemplateEquipmentsList[] = [];
      newArr.push(tempEquipmentList);
      const dataVal: ITemplateEquipments = {
        equipmentCategoryId: 'C00000',
        equipmentCategory: '',
        equipmentList: newArr
      };

      setTemplateEquipmentsSummaryData([dataVal]);
      setSelectedTemplateEquipmentLabel('Custom');
    } else {
      const tempArr: any = jsonData.filter((item) => item.templateId === data);
      const testArr = tempArr[0].templateEquipments.map(
        (listItem: ITemplateEquipments) => ({
          ...listItem,
          equipmentList: updateEquipmentDetailsList(
            listItem.equipmentCategoryId,
            listItem.equipmentList
          )
        })
      );
      tempArr[0].templateEquipments = testArr;
      setSelectedTemplateEquipmentLabel(tempArr[0].templateName);
      setTemplateEquipmentsSummaryData(tempArr[0].templateEquipments);
    }
  };

  const createEquipmentCategory = () => {
    const tempEquipmentList: ITemplateEquipmentsList = {
      equipmentId: '',
      equipmentName: '',
      noOfDevices: 0,
      kwHUsage: 0,
      equipmentMode: '',
      equipmentCategoryValue: '',
      equipmentListOptions: [],
      equipmentCategoryTextValue: ''
    };
    const newArr: ITemplateEquipmentsList[] = [];
    newArr.push(tempEquipmentList);
    const data: ITemplateEquipments = {
      equipmentCategoryId: 'C00000',
      equipmentCategory: '',
      equipmentList: newArr
    };
    setTemplateEquipmentsSummaryData([...templateEquipmentsSummaryData, data]);
  };

  const updateEquipmentCategory = (data: string, i: number, index: number) => {
    if (data === 'C99999') {
      templateEquipmentsSummaryData[i].equipmentList[index].equipmentName = '';
    }
    templateEquipmentsSummaryData[i].equipmentList[
      index
    ].equipmentCategoryValue = data;
    templateEquipmentsSummaryData[i].equipmentList[
      index
    ].equipmentCategoryTextValue = equipmentCategoryOptions.find(
      (item) => item.value === data
    ).text;
    templateEquipmentsSummaryData[i].equipmentList[
      index
    ].equipmentListOptions = getEquipmentsDetailsList(data);
    setTemplateEquipmentsSummaryData([...templateEquipmentsSummaryData]);
  };

  const updateEquipmentName = (data: string, index: number, i: number) => {
    const findValue = jsonData.flatMap((item) => {
      const templateEquipmentsArr = item.templateEquipments.flatMap(
        (templateItem) => {
          const equipmentsListArr = templateItem.equipmentList.find(
            (equipmentItem) => equipmentItem.equipmentName === data
          );
          if (equipmentsListArr) {
            templateEquipmentsSummaryData[i].equipmentList[index].kwHUsage =
              equipmentsListArr.kwHUsage;
          }
        }
      );
    });
    templateEquipmentsSummaryData[i].equipmentList[index].equipmentName = data;
    if (templateEquipmentsSummaryData[i].equipmentList[index].noOfDevices < 1) {
      templateEquipmentsSummaryData[i].equipmentList[index].noOfDevices = 1;
    }
    setTemplateEquipmentsSummaryData([...templateEquipmentsSummaryData]);
  };

  const updateEquipmentNoOfDevices = (
    value: number,
    index: number,
    i: number
  ) => {
    templateEquipmentsSummaryData[i].equipmentList[index].noOfDevices = value;
    setTemplateEquipmentsSummaryData([...templateEquipmentsSummaryData]);
  };

  const updateEquipmentKwHUsage = (value: number, index: number, i: number) => {
    templateEquipmentsSummaryData[i].equipmentList[index].kwHUsage = value;
    setTemplateEquipmentsSummaryData([...templateEquipmentsSummaryData]);
  };

  const handleRemoveEquipmentsRow = (index: number, i: number) => {
    if (selectedTemplateEquipment.length === 0) {
      return false;
    }
    const tempRows = [...templateEquipmentsSummaryData];
    tempRows[i].equipmentList.splice(index, 1);
    setTemplateEquipmentsSummaryData([...tempRows]);
  };

  const handleAddEquipmentsRow = () => {
    if (
      templateEquipmentsSummaryData &&
      templateEquipmentsSummaryData.length > 0
    ) {
      const tempEquipmentList: ITemplateEquipmentsList = {
        equipmentId: '',
        equipmentName: '',
        noOfDevices: 0,
        kwHUsage: 0,
        equipmentMode: '',
        equipmentCategoryValue: '',
        equipmentListOptions: [],
        equipmentCategoryTextValue: ''
      };
      const data: ITemplateEquipments = {
        equipmentCategoryId: '',
        equipmentCategory: '',
        equipmentList: [tempEquipmentList]
      };
      setTemplateEquipmentsSummaryData([
        ...templateEquipmentsSummaryData,
        data
      ]);
    } else {
      createEquipmentCategory();
    }
  };

  const showResultsCharts = () => {
    const totalNoUnOccupiedOfHours: number =
      (DAILY_HOURS - unOccupiedHoursData.Monday
        ? unOccupiedHoursData.Monday
        : 0) +
      (DAILY_HOURS - unOccupiedHoursData.Tuesday
        ? unOccupiedHoursData.Tuesday
        : 0) +
      (DAILY_HOURS - unOccupiedHoursData.Wednesday
        ? unOccupiedHoursData.Wednesday
        : 0) +
      (DAILY_HOURS - unOccupiedHoursData.Thursday
        ? unOccupiedHoursData.Thursday
        : 0) +
      (DAILY_HOURS - unOccupiedHoursData.Friday
        ? unOccupiedHoursData.Friday
        : 0) +
      (DAILY_HOURS - unOccupiedHoursData.Saturday
        ? unOccupiedHoursData.Saturday
        : 0) +
      (DAILY_HOURS - unOccupiedHoursData.Sunday
        ? unOccupiedHoursData.Sunday
        : 0);
    let totalNoOfDevices = 0,
      totalNoOfDevicesAndKwH = 0,
      totalNoOfKWHAndCostPerHr = 0;
    templateEquipmentsSummaryData.flatMap((item) => {
      totalNoOfDevices += item.equipmentList.reduce(
        (accumulator, currentValue) => accumulator + currentValue.noOfDevices,
        0
      );
      totalNoOfDevicesAndKwH += item.equipmentList.reduce(
        (accumulator, currentValue) =>
          accumulator +
          currentValue.noOfDevices *
            (currentValue.kwHUsage / KILO_WATT_CONVERSION),
        0
      );
      totalNoOfKWHAndCostPerHr += item.equipmentList.reduce(
        (accumulator, currentValue) =>
          accumulator +
          (currentValue.kwHUsage / KILO_WATT_CONVERSION) *
            energyCalculatorSummary.energyCost,
        0
      );
    });
    const savedEnergyData: number =
      totalNoUnOccupiedOfHours * totalNoOfDevicesAndKwH;
    const savedEnergyDataMonth: number =
      totalNoUnOccupiedOfHours * MONTHLY_WEEKS * totalNoOfDevicesAndKwH;
    const savedEnergyDataYear: number =
      totalNoUnOccupiedOfHours * YEARLY_WEEKS * totalNoOfDevicesAndKwH;
    const weeklyTotal = DAILY_HOURS * WEEKLY_DAYS * totalNoOfDevicesAndKwH;
    const monthlyTotal = DAILY_HOURS * MONTHLY_DAYS * totalNoOfDevicesAndKwH;
    const yearlyTotal = DAILY_HOURS * YEARLY_DAYS * totalNoOfDevicesAndKwH;
    setTotalWorkingHours(totalNoUnOccupiedOfHours);
    setTotalWorkingHoursMonthly(totalNoUnOccupiedOfHours * MONTHLY_WEEKS);
    setTotalWorkingHoursYearly(totalNoUnOccupiedOfHours * YEARLY_WEEKS);
    setTotalEquipments(totalNoOfDevices);
    setTotalEquipmentsWithkWh(totalNoOfDevicesAndKwH);

    setOutOfHoursWeeklyUse('' + weeklyTotal.toFixed(PRECISION_LENGTH));
    setOutOfHoursMonthlyUse('' + monthlyTotal.toFixed(PRECISION_LENGTH));
    setOutOfHoursYearlyUse('' + yearlyTotal.toFixed(PRECISION_LENGTH));
    setAfterScheduleWeeklyUse(savedEnergyData.toFixed(PRECISION_LENGTH) + '');
    setAfterScheduleMonthlyUse(
      savedEnergyDataMonth.toFixed(PRECISION_LENGTH) + ''
    );
    setAfterScheduleYearlyUse(
      savedEnergyDataYear.toFixed(PRECISION_LENGTH) + ''
    );

    const costSavePerWeek =
      savedEnergyData * energyCalculatorSummary.energyCost;
    const costSavePerMonth =
      savedEnergyDataMonth * energyCalculatorSummary.energyCost;
    const costSavePerYear =
      savedEnergyDataYear * energyCalculatorSummary.energyCost;
    const weeklyTotalCost = weeklyTotal * energyCalculatorSummary.energyCost;
    const monthlyTotalCost = monthlyTotal * energyCalculatorSummary.energyCost;
    const yearlyTotalCost = yearlyTotal * energyCalculatorSummary.energyCost;

    setOutOfHoursWeeklyCost('' + weeklyTotalCost.toFixed(PRECISION_LENGTH));
    setOutOfHoursMonthlyCost('' + monthlyTotalCost.toFixed(PRECISION_LENGTH));
    setOutOfHoursYearlyCost('' + yearlyTotalCost.toFixed(PRECISION_LENGTH));
    setAfterScheduleWeeklyCost(costSavePerWeek.toFixed(PRECISION_LENGTH) + '');
    setAfterScheduleMonthlyCost(
      costSavePerMonth.toFixed(PRECISION_LENGTH) + ''
    );

    setAfterScheduleYearlyCost(costSavePerYear.toFixed(PRECISION_LENGTH) + '');
  };

  function Form(props) {
    const {
      baseUrl,
      munchkinId,
      formId,
      onFormSubmit,
      onFormSuccess,
      onFormLoad
    } = props;
    if (!(baseUrl && munchkinId && formId)) {
      return <div>Fill the fields and a form should appear</div>;
    }
    const { scriptLoaded, formLoaded } = useMarketo({
      baseUrl,
      munchkinId,
      formId,
      onFormSubmit,
      onFormSuccess,
      onFormLoad
    });
    return <form id={`mktoForm_${formId}`} />;
  }

  const handleFormLoad = (loaded) => {
    if (loaded) {
      setIsLoadingForm(false);
    } else {
      setIsLoadingForm(true);
    }
  };

  const handleFormSubmit = () => {};

  const handleFormSuccess = (values) => {
    setIsMarketoFormSubmitted(true);
    setToggleMarketoModal(false);
    proceedToDownloadPDF(true);
  };

  const proceedToDownloadPDF = (flag) => {
    setToggleMarketoModal(false);
    if (flag) {
      const weeklyChartContainerDiv: HTMLElement = weeklyChartContainerRef.current!;
      toJpeg(weeklyChartContainerDiv, {
        quality: 1.0,
        backgroundColor: 'white'
      }).then(function(weeklyDataUrl) {
        const monthlyChartContainerDiv: HTMLElement = monthlyChartContainerRef.current!;
        toJpeg(monthlyChartContainerDiv, {
          quality: 1.0,
          backgroundColor: 'white'
        }).then(function(monthlyDataUrl) {
          const annualChartContainerDiv: HTMLElement = annualChartContainerRef.current!;
          toJpeg(annualChartContainerDiv, {
            quality: 1.0,
            backgroundColor: 'white'
          }).then(function(annualDataUrl) {
            setIsLoading(true);
            let debounceResize: number = 0,
              interval = 2000;
            debounceResize = window.setTimeout(() => {
              setIsLoading(false);
            }, interval);
            let link = document.createElement('a');
            link.download = 'my-image-name.jpeg';
            const pdf: any = new jsPDF();
            pdf.setFont('HoneywellSansWeb-Extrabold', 'bold');
            pdf.setFontSize(30);
            pdf.setTextColor(221, 31, 45);
            pdf.text(10, 20, 'PLUG LOAD ENERGY');
            pdf.setFontSize(30);
            pdf.setTextColor(221, 31, 45);
            pdf.text(10, 30, 'CALCULATOR');
            pdf.setFontSize(30);
            pdf.setTextColor(0, 0, 0);
            pdf.text(10, 42, 'REPORT');
            pdf.setFontSize(14);
            pdf.setTextColor(0, 0, 0);
            pdf.text(10, 50, 'BUILDING INFORMATION');
            pdf.setFont('HoneywellSansWeb-Medium', 'normal');
            pdf.setFontSize(11);
            pdf.text(
              10,
              56,
              'Building Type: ' + selectedTemplateEquipmentLabel
            );
            pdf.text(10, 62, 'Created on: ' + new Date().toDateString());
            pdf.setFontSize(14);
            pdf.setTextColor(0, 0, 0);
            pdf.setFont('HoneywellSansWeb-Extrabold', 'bold');
            pdf.text(10, 75, 'ESTIMATED SAVINGS');

            pdf.addImage(weeklyDataUrl, 'JPG', 10, 82, 65, 100);
            pdf.addImage(monthlyDataUrl, 'JPG', 75, 82, 65, 100);
            pdf.addImage(annualDataUrl, 'JPG', 140, 82, 65, 100);

            pdf.setDrawColor(208, 208, 208);
            pdf.line(10, 182, 210, 182);

            pdf.setFontSize(11);
            pdf.setFont('HoneywellSansWeb-Medium', 'normal');
            pdf.setFont('NotoSans-ExtraBold', 'bold');
            pdf.text(
              10,
              191,
              'Unit cost: ' +
                currencyTypeSymbol +
                energyCalculatorSummary.energyCost +
                ' /kWh'
            );

            pdf.setFont('NotoSans-ExtraBold', 'bold');
            pdf.setFontSize(14);
            pdf.text(10, 203, 'SUMMARY');

            pdf.setDrawColor(90, 90, 90);
            pdf.line(10, 205, 200, 205);

            const firstSectionY = 217;
            pdf.setFontSize(12);
            pdf.setFont('HoneywellSansWeb-Medium', 'normal');
            pdf.text('Total no. of equipment', 14, firstSectionY + 5);
            pdf.text('Total usage of all the equipment', 70, firstSectionY + 5);
            pdf.text('Unit cost', 160, firstSectionY + 5);

            pdf.setFont('HoneywellSansWeb-Extrabold', 'bold');
            pdf.setFontSize(18);
            pdf.text('' + totalEquipments, 30, firstSectionY + 15, {
              maxWidth: 35
            });
            pdf.text(
              '' + totalEquipmentsWithkWh.toLocaleString() + ' kW',
              85,
              firstSectionY + 15,
              { maxWidth: 50 }
            );
            pdf.setFont('NotoSans-ExtraBold', 'bold');
            pdf.text(
              '' +
                currencyTypeSymbol +
                ' ' +
                energyCalculatorSummary.energyCost,
              160,
              firstSectionY + 15
            );

            pdf.line(65, firstSectionY, 65, firstSectionY + 20);
            pdf.line(145, firstSectionY, 145, firstSectionY + 20);

            pdf.rect(10, firstSectionY - 5, 190, 30);
            const startY = 252;

            const weeklyX = 10;
            const weeklyWidth = 60;
            const weeklyHeight = 35;
            pdf.setFontSize(11);
            pdf.setFont('HoneywellSansWeb-Medium', 'normal');
            pdf.text('WEEKLY', weeklyX + 2, startY);
            pdf.line(weeklyX, startY + 2, weeklyX + weeklyWidth, startY + 2);
            pdf.autoTable({
              startY: startY - 5,
              head: [['', '']],
              body: [
                [
                  'Total equipment running hours',
                  DAILY_HOURS * WEEKLY_DAYS + ' hrs'
                ],
                [
                  'Average hours building is occupied',
                  Math.floor(totalWorkingHours) + ' hrs'
                ]
              ],
              theme: 'plain',
              styles: {
                fontSize: 9,
                cellPadding: 2,
                minCellHeight: 7,
                font: 'HoneywellSansWeb-Medium',
                textColor: [0, 0, 0]
              },
              columnStyles: {
                0: { cellWidth: 40 },
                1: { cellWidth: 20 }
              },
              margin: { left: weeklyX }
            });
            pdf.rect(weeklyX, startY - 7, weeklyWidth, weeklyHeight);

            const monthlyX = 75;
            const monthlyWidth = 60;
            const monthlyHeight = 35;
            pdf.setFontSize(11);
            pdf.text('MONTHLY', monthlyX + 2, startY);
            pdf.line(monthlyX, startY + 2, monthlyX + monthlyWidth, startY + 2);
            pdf.autoTable({
              startY: startY - 5,
              head: [['', '']],
              body: [
                [
                  'Total equipment running hours',
                  DAILY_HOURS * MONTHLY_DAYS + ' hrs'
                ],
                [
                  'Average hours building is occupied',
                  Math.floor(totalWorkingHoursMonthly) + ' hrs'
                ]
              ],
              theme: 'plain',
              styles: {
                fontSize: 9,
                cellPadding: 2,
                minCellHeight: 7,
                font: 'HoneywellSansWeb-Medium',
                textColor: [0, 0, 0]
              },
              columnStyles: {
                0: { cellWidth: 40 },
                1: { cellWidth: 20 }
              },
              margin: { left: monthlyX }
            });
            pdf.rect(monthlyX, startY - 7, monthlyWidth, monthlyHeight);

            const annualX = 140;
            const annualWidth = 60;
            const annualHeight = 35;
            pdf.setFontSize(11);
            pdf.text('ANNUALLY', annualX + 2, startY);
            pdf.line(annualX, startY + 2, annualX + annualWidth, startY + 2);
            pdf.autoTable({
              startY: startY - 5,
              head: [['', '']],
              body: [
                [
                  'Total equipment running hours',
                  DAILY_HOURS * YEARLY_DAYS + ' hrs'
                ],
                [
                  'Average hours building is occupied',
                  Math.floor(totalWorkingHoursYearly) + ' hrs'
                ]
              ],
              theme: 'plain',
              styles: {
                fontSize: 9,
                cellPadding: 2,
                minCellHeight: 7,
                font: 'HoneywellSansWeb-Medium',
                textColor: [0, 0, 0]
              },
              columnStyles: {
                0: { cellWidth: 40 },
                1: { cellWidth: 20 }
              },
              margin: { left: annualX }
            });
            pdf.rect(annualX, startY - 7, annualWidth, annualHeight);

            pdf.addPage();
            pdf.setFontSize(14);
            pdf.setFont('HoneywellSansWeb-Extrabold', 'bold');
            pdf.text(10, 15, 'WEEKLY SCHEDULE');

            pdf.setDrawColor(90, 90, 90);
            pdf.line(10, 17, 200, 17);

            const scheduleHeaders = [
              ['Days', 'Occupied Hours', 'Unoccupied Hours']
            ];
            const scheduleTempArr: any = unOccupiedHoursData;
            const scheduleData = Object.keys(scheduleTempArr).map((item) => ({
              key: item,
              value: scheduleTempArr[item] ? scheduleTempArr[item] : 0,
              ...scheduleTempArr[item]
            }));

            const scheduledWeeksData = scheduleData.map((element) => [
              element.key,
              element.value,
              24 - element.value
            ]);
            autoTable(pdf, {
              head: scheduleHeaders,
              body: scheduledWeeksData,
              useCss: true,
              startY: 25,
              headStyles: {
                fillColor: [224, 224, 224],
                textColor: [0, 0, 0],
                fontSize: 12,
                font: 'HoneywellSansWeb-Extrabold'
              },
              bodyStyles: {
                fontSize: 11,
                font: 'HoneywellSansWeb-Medium',
                cellPadding: { top: 1, right: 5, bottom: 1, left: 2 },
                textColor: [0, 0, 0],
                lineWidth: {
                  bottom: 0.5
                }
              },
              columnStyles: {
                0: { halign: 'left' },
                1: { halign: 'right' },
                2: { halign: 'right' }
              },
              didParseCell: function(data) {
                if (data.section === 'head') {
                  if (data.column.index === 0) {
                    data.cell.styles.halign = 'left';
                  } else {
                    data.cell.styles.halign = 'right';
                  }
                }
              },
              foot: [
                [
                  {
                    content: 'Total',
                    styles: {
                      fillColor: [224, 224, 224],
                      textColor: [0, 0, 0],
                      fontSize: 12,
                      font: 'HoneywellSansWeb-Extrabold',
                      halign: 'left'
                    }
                  },
                  {
                    content: totalWorkingHours + ' hrs',
                    styles: {
                      fillColor: [224, 224, 224],
                      textColor: [0, 0, 0],
                      fontSize: 12,
                      font: 'HoneywellSansWeb-Extrabold',
                      halign: 'right'
                    }
                  },
                  {
                    content: 168 - totalWorkingHours + ' hrs',
                    styles: {
                      fillColor: [224, 224, 224],
                      textColor: [0, 0, 0],
                      fontSize: 12,
                      font: 'HoneywellSansWeb-Extrabold',
                      halign: 'right'
                    }
                  }
                ]
              ]
            });

            pdf.setFontSize(14);
            pdf.setFont('HoneywellSansWeb-Extrabold', 'bold');
            pdf.text(10, 105, 'EQUIPMENT DETAILS');

            pdf.setDrawColor(90, 90, 90);
            pdf.line(10, 107, 200, 107);

            const combinedTableData: any = [];
            templateEquipmentsSummaryData.forEach((parentRow) => {
              parentRow.equipmentList.forEach((childRow, index) => {
                combinedTableData.push({
                  equipmentCategory: childRow.equipmentCategoryTextValue,
                  equipmentName: childRow.equipmentName,
                  kwHUsage: childRow.kwHUsage,
                  noOfDevices: childRow.noOfDevices,
                  totalPowerUsage: (
                    childRow.kwHUsage * childRow.noOfDevices
                  ).toFixed(2)
                });
              });
            });
            const combinedTableColumns = [
              {
                header: 'Equipment Category',
                dataKey: 'equipmentCategory'
              },
              { header: 'Type', dataKey: 'equipmentName' },
              { header: 'Power Usage (W)*', dataKey: 'kwHUsage' },
              { header: 'No.of Device', dataKey: 'noOfDevices' },
              { header: 'Total Power Usage (W)', dataKey: 'totalPowerUsage' }
            ];
            let dynamicHeightForPdf = 0;
            pdf.autoTable({
              head: [combinedTableColumns.map((col) => col.header)],
              body: combinedTableData.map((row: any) =>
                combinedTableColumns.map((col) => row[col.dataKey])
              ),
              foot: [
                [
                  {
                    content: 'Total',
                    dataKey: '',
                    styles: {
                      fillColor: [224, 224, 224],
                      textColor: [0, 0, 0],
                      fontSize: 12,
                      font: 'HoneywellSansWeb-Extrabold',
                      halign: 'left'
                    }
                  },
                  {
                    content: '',
                    dataKey: '',
                    styles: {
                      fillColor: [224, 224, 224],
                      textColor: [0, 0, 0]
                    }
                  },
                  {
                    content: '',
                    dataKey: '',
                    styles: {
                      fillColor: [224, 224, 224],
                      textColor: [0, 0, 0]
                    }
                  },
                  {
                    content: totalEquipments,
                    dataKey: '',
                    styles: {
                      fillColor: [224, 224, 224],
                      textColor: [0, 0, 0],
                      fontSize: 12,
                      font: 'HoneywellSansWeb-Extrabold',
                      halign: 'right'
                    }
                  },
                  {
                    content:
                      (totalEquipmentsWithkWh * KILO_WATT_CONVERSION).toFixed(
                        2
                      ) +
                      ' W (' +
                      totalEquipmentsWithkWh.toFixed(2) +
                      ' kW)',
                    dataKey: '',
                    styles: {
                      fillColor: [224, 224, 224],
                      textColor: [0, 0, 0],
                      fontSize: 12,
                      font: 'HoneywellSansWeb-Extrabold',
                      halign: 'right'
                    }
                  }
                ]
              ],
              margin: { bottom: 10 },
              theme: 'striped',
              useCss: true,
              didDrawPage: (d) => {
                dynamicHeightForPdf = d.cursor.y;
              },
              startY: 115,
              headStyles: {
                fillColor: [224, 224, 224],
                textColor: [0, 0, 0],
                fontSize: 11,
                font: 'HoneywellSansWeb-Extrabold',
                halign: 'left'
              },
              bodyStyles: {
                fontSize: 11,
                font: 'HoneywellSansWeb-Medium',
                cellPadding: { top: 1, right: 5, bottom: 1, left: 2 },
                textColor: [0, 0, 0],
                lineWidth: {
                  bottom: 0.5
                }
              },
              columnStyles: {
                2: { halign: 'right' },
                3: { halign: 'right' },
                4: { halign: 'right' }
              }
            });
            pdf.setFontSize(10);
            pdf.setFont('HoneywellSansWeb-Medium', 'normal');
            pdf.setTextColor(90, 90, 90);
            pdf.text(
              15,
              dynamicHeightForPdf + 10,
              "*Power usage estimates were derived from the U.S. Environmental Protection Agency's and U.S. Department"
            );
            pdf.text(
              15,
              dynamicHeightForPdf + 14,
              "of Energy's Energy Star® website as of October 2024 available at:"
            );
            pdf.textWithLink('ENERGY STAR.', 123, dynamicHeightForPdf + 14, {
              url: 'https://www.energystar.gov/'
            });
            pdf.line(
              123,
              dynamicHeightForPdf + 15,
              149,
              dynamicHeightForPdf + 15
            );
            addFooters(pdf);
            const arrayBuffer1 = pdf.output('arraybuffer');
            loadPDF(arrayBuffer1);
          });
        });
      });
    }
  };

  const exportToPDF = () => {
    if (totalEquipments < 1) {
      setToggleResultResponseModal(true);
      return false;
    }

    setToggleMarketoModal(true);
    const propers = {
      baseUrl: 'https://qhbt.honeywell.com',
      munchkinId: '626-WZX-710',
      formId: '7028',
      callback: 'callbackForMarketo'
    };
    setInputs(propers);
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const loadPDF = async (arrayBuffer1) => {
    let arrBuff: any = [];
    const coverPageStr = PDFJson.filter((item) => item.name === 'cover');
    const tailPageStr = PDFJson.filter((item) => item.name === 'tail');
    const coverPagePdfBytes = base64ToArrayBuffer(coverPageStr[0].content);
    const tailPagePdfBytes = base64ToArrayBuffer(tailPageStr[0].content);
    arrBuff = [coverPagePdfBytes, arrayBuffer1, tailPagePdfBytes];
    mergePdfs(arrBuff);
  };

  const mergePdfs = async (pdfsToMerge: any) => {
    const mergedPdf = await PDFDocument.create();
    for (const pdfCopyDoc of pdfsToMerge) {
      try {
        const pdfDoc = await PDFDocument.load(new Uint8Array(pdfCopyDoc));
        const copiedPages = await mergedPdf.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      } catch (error) {
        console.error('Error', error);
      }
    }
    const mergedPdfFile = await mergedPdf.save();
    downloadMergedPDF(mergedPdfFile);
    return mergedPdfFile;
  };

  const downloadMergedPDF = (mergedPdfFile) => {
    const blob = new Blob([mergedPdfFile], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      'Honeywell_ConnectedPower_EnergyEstimationReport.pdf'
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const addFooters = (doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont('HoneywellSansWeb-Medium', 'normal');
    doc.setFontSize(9);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        'Energy Usage Report ' + ' | ' + String(i),
        doc.internal.pageSize.width - 15,
        287,
        {
          align: 'right'
        }
      );
    }
  };

  const renderEquipmentCategoryCell = (item, i, index) => {
    return (
      <Table.Cell>
        <Select
          value={item.equipmentCategoryValue}
          options={equipmentCategoryOptions}
          key={index}
          disabled={selectedTemplateEquipment.length === 0}
          className="equipment-category-selection"
          placeholder="Select"
          onChange={(data) => updateEquipmentCategory(data, i, index)}
        />
      </Table.Cell>
    );
  };

  const renderEquipmentNameCell = (item, i, index) => {
    let cellContent;

    if (item.equipmentCategoryValue === 'C99999') {
      cellContent = (
        <Input
          className="input-example"
          value={item.equipmentName}
          disabled={
            selectedTemplateEquipment.length === 0 ||
            item.equipmentCategoryValue?.length === 0
          }
          key={item.equipmentId}
          onChange={(data: any) => {
            const validated = data.match(/^[a-zA-Z0-9'"/()\-_@]*$/);
            if (validated) {
              updateEquipmentName(data, index, i);
            }
          }}
        />
      );
    } else {
      cellContent = (
        <Select
          value={item.equipmentName}
          placeholder="Select"
          options={item.equipmentListOptions ?? []}
          key={item.equipmentName}
          className="equipment-name-selection"
          disabled={
            selectedTemplateEquipment.length === 0 ||
            item.equipmentCategoryValue?.length === 0
          }
          onChange={(data) => {
            updateEquipmentName(data, index, i);
          }}
        />
      );
    }

    return <Table.Cell>{cellContent}</Table.Cell>;
  };

  const renderKwHUsageCell = (item, index, i) => {
    return (
      <Table.Cell>
        <Input
          className="input-example power-usage-text"
          disabled={true}
          type="number"
          value={'' + item.kwHUsage}
          min={0}
          step={0.1}
          onChange={(data: any) => {
            const validated = data.match(/^\d{0,7}(?:\.\d{0,4})?$/);
            if (validated) {
              updateEquipmentKwHUsage(data, index, i);
            }
          }}
        />
      </Table.Cell>
    );
  };

  const renderNoOfDevicesCell = (item, index, i) => {
    return (
      <Table.Cell>
        <Input
          type="number"
          disabled={
            selectedTemplateEquipment.length === 0 ||
            item.equipmentCategoryValue?.length === 0
          }
          className="input-example equipment-quantity"
          value={'' + item.noOfDevices}
          onChange={(data: any) => {
            const validated = data.match(/^\d{0,7}?$/);
            if (validated) {
              data = data === '' ? 0 : data;
              updateEquipmentNoOfDevices(parseInt(data, 10), index, i);
            }
          }}
        />
      </Table.Cell>
    );
  };

  const renderDeleteButton = (index, i) => {
    return (
      <Table.Cell textAlign="right">
        <Icon
          name="delete"
          root="global"
          size="large"
          className="delete-row-button"
          onClick={() => handleRemoveEquipmentsRow(index, i)}
        />
      </Table.Cell>
    );
  };

  return (
    <main className="energy-calculator-container">
      <div className="container">
        <div className="form-container">
          {isLoading === true ? (
            <Loader text="Loading" overlayOpacity={0.2} />
          ) : (
            <div className="right-container">
              <h3 className="right-header-title">Building Information</h3>

              <h4 className="section-header-label">
                1. Please enter number of hours your building is occupied*{' '}
              </h4>
              <div
                className="form-content"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div className="weekly-container">
                  <Input
                    className="input-example"
                    type="number"
                    value={'' + unOccupiedHoursData.Monday}
                    min={0}
                    max={DAILY_HOURS}
                    step={1}
                    label="Monday (Hrs)"
                    placeholder="Hours"
                    onChange={(data: any) => {
                      const regex = /^(\d|1\d|2[0-4])?$/;
                      if (data.match(regex)) {
                        setUnOccupiedHoursData({
                          ...unOccupiedHoursData,
                          Monday: parseFloat(data)
                        });
                        setEnergyCalculatorSummary({
                          ...energyCalculatorSummary,
                          unOccupiedHours: unOccupiedHoursData
                        });
                      }
                    }}
                  />
                  <Input
                    className="input-example"
                    type="number"
                    value={'' + unOccupiedHoursData.Tuesday}
                    min={0}
                    max={DAILY_HOURS}
                    step={1}
                    label="Tuesday (Hrs)"
                    placeholder="Hours"
                    onChange={(data: any) => {
                      const regex = /^(\d|1\d|2[0-4])?$/;
                      if (data.match(regex)) {
                        setUnOccupiedHoursData({
                          ...unOccupiedHoursData,
                          Tuesday: parseFloat(data)
                        });
                        setEnergyCalculatorSummary({
                          ...energyCalculatorSummary,
                          unOccupiedHours: unOccupiedHoursData
                        });
                      }
                    }}
                  />
                  <Input
                    className="input-example"
                    type="number"
                    value={'' + unOccupiedHoursData.Wednesday}
                    min={0}
                    max={DAILY_HOURS}
                    step={1}
                    label="Wednesday (Hrs)"
                    placeholder="Hours"
                    onChange={(data: any) => {
                      const regex = /^(\d|1\d|2[0-4])?$/;
                      if (data.match(regex)) {
                        setUnOccupiedHoursData({
                          ...unOccupiedHoursData,
                          Wednesday: parseFloat(data)
                        });
                        setEnergyCalculatorSummary({
                          ...energyCalculatorSummary,
                          unOccupiedHours: unOccupiedHoursData
                        });
                      }
                    }}
                  />
                  <Input
                    className="input-example"
                    type="number"
                    value={'' + unOccupiedHoursData.Thursday}
                    min={0}
                    max={DAILY_HOURS}
                    step={1}
                    label="Thursday (Hrs)"
                    placeholder="Hours"
                    onChange={(data: any) => {
                      const regex = /^(\d|1\d|2[0-4])?$/;
                      if (data.match(regex)) {
                        setUnOccupiedHoursData({
                          ...unOccupiedHoursData,
                          Thursday: parseFloat(data)
                        });
                        setEnergyCalculatorSummary({
                          ...energyCalculatorSummary,
                          unOccupiedHours: unOccupiedHoursData
                        });
                      }
                    }}
                  />
                  <Input
                    className="input-example friday"
                    type="number"
                    value={'' + unOccupiedHoursData.Friday}
                    min={0}
                    max={DAILY_HOURS}
                    step={1}
                    label="Friday (Hrs)"
                    placeholder="Hours"
                    onChange={(data: any) => {
                      const regex = /^(\d|1\d|2[0-4])?$/;
                      if (data.match(regex)) {
                        setUnOccupiedHoursData({
                          ...unOccupiedHoursData,
                          Friday: parseFloat(data)
                        });
                        setEnergyCalculatorSummary({
                          ...energyCalculatorSummary,
                          unOccupiedHours: unOccupiedHoursData
                        });
                      }
                    }}
                  />
                  <Input
                    className="input-example"
                    type="number"
                    value={'' + unOccupiedHoursData.Saturday}
                    min={0}
                    max={DAILY_HOURS}
                    step={1}
                    label="Saturday (Hrs)"
                    placeholder="Hours"
                    onChange={(data: any) => {
                      const regex = /^(\d|1\d|2[0-4])?$/;
                      if (data.match(regex)) {
                        setUnOccupiedHoursData({
                          ...unOccupiedHoursData,
                          Saturday: parseFloat(data)
                        });
                        setEnergyCalculatorSummary({
                          ...energyCalculatorSummary,
                          unOccupiedHours: unOccupiedHoursData
                        });
                      }
                    }}
                  />
                  <Input
                    className="input-example"
                    type="number"
                    value={'' + unOccupiedHoursData.Sunday}
                    min={0}
                    max={DAILY_HOURS}
                    step={1}
                    label="Sunday (Hrs)"
                    placeholder="Hours"
                    onChange={(data: any) => {
                      const regex = /^(\d|1\d|2[0-4])?$/;
                      if (data.match(regex)) {
                        setUnOccupiedHoursData({
                          ...unOccupiedHoursData,
                          Sunday: parseFloat(data)
                        });
                        setEnergyCalculatorSummary({
                          ...energyCalculatorSummary,
                          unOccupiedHours: unOccupiedHoursData
                        });
                      }
                    }}
                  />
                </div>

                <h4 className="section-header-label">
                  2. Please enter your energy cost details*
                </h4>
                <div className="currency-container">
                  <Select
                    value={currencyType}
                    options={currenctTypeOptions}
                    label="Currency"
                    className="currency-selection"
                    onChange={(data) => {
                      switch (data) {
                        case 'USD': {
                          setCurrencyTypeSymbol('$');
                          setCurrencyType('USD');
                          break;
                        }
                        case 'GBP': {
                          setCurrencyTypeSymbol('£');
                          setCurrencyType('GBP');
                          break;
                        }
                        case 'EUR': {
                          setCurrencyTypeSymbol('€');
                          setCurrencyType('EUR');
                          break;
                        }
                        case 'CAD': {
                          setCurrencyTypeSymbol('C$');
                          setCurrencyType('CAD');
                          break;
                        }
                        case 'JPY': {
                          setCurrencyTypeSymbol('¥');
                          setCurrencyType('JPY');
                          break;
                        }
                        case 'INR': {
                          setCurrencyTypeSymbol('₹');
                          setCurrencyType('INR');
                          break;
                        }
                        default: {
                          setCurrencyTypeSymbol('£');
                          break;
                        }
                      }
                    }}
                  />
                  <div>
                    <Input
                      className="input-example energy-cost-content"
                      type="number"
                      label="Unit cost (cost of 1 kWh)"
                      value={'' + energyCalculatorSummary.energyCost}
                      min={0}
                      step={0.1}
                      placeholder="Current cost per kWh"
                      onChange={(data: any) => {
                        const validated = data.match(/^\d{0,7}(?:\.\d{0,4})?$/);
                        if (validated) {
                          setEnergyCalculatorSummary({
                            ...energyCalculatorSummary,
                            energyCost: data
                          });
                        }
                      }}
                    />

                    <div className="below-text">
                      {energyCalculatorSummary.energyCost <= 0 && (
                        <span className="ui error-message">
                          Please enter your unit cost
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <h4 className="section-header-label">
                  3. Please enter equipment details*
                </h4>
                <div className="building-type-container">
                  <Select
                    label="Choose type of building"
                    value={selectedTemplateEquipment}
                    placeholder="Select"
                    options={quickPopulationOptions}
                    className="quick-population-selection"
                    onChange={(data) => {
                      handleTemplateTypeChange(data);
                    }}
                  ></Select>
                </div>

                <div className="summary-form-container">
                  <div className="summary-left-container">
                    <div className="equipment-summary-container">
                      <Table className="equipment-summary-table">
                        <Table.Header>
                          <Table.HeaderCell content="Equipment category" />
                          <Table.HeaderCell content="Type" />
                          <Table.HeaderCell content="Power usage (W)*" />
                          <Table.HeaderCell content="Quantity" />
                          <Table.HeaderCell content="" />
                        </Table.Header>
                        <Table.Body>
                          {templateEquipmentsSummaryData.map((list, i) => (
                            <React.Fragment key={i}>
                              {list.equipmentList.map((item, index) => (
                                <Table.Row key={index}>
                                  {renderEquipmentCategoryCell(item, i, index)}
                                  {renderEquipmentNameCell(item, i, index)}
                                  {renderKwHUsageCell(item, index, i)}
                                  {renderNoOfDevicesCell(item, index, i)}
                                  {renderDeleteButton(index, i)}
                                </Table.Row>
                              ))}
                            </React.Fragment>
                          ))}
                        </Table.Body>
                        <Table.Footer>
                          <Table.Row>
                            <Table.HeaderCell colspan={5}>
                              <Button
                                type="primary"
                                size="medium"
                                disabled={selectedTemplateEquipment.length == 0}
                                content="ADD EQUIPMENT"
                                className="button-style-add"
                                onClick={handleAddEquipmentsRow}
                              />
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Footer>
                      </Table>
                    </div>
                  </div>
                </div>

                <div className="line-break-container"></div>

                <div className="summary-table-container">
                  <h3 className="equipment-summary-title">Summary</h3>
                  <div className="">
                    <div className="sum-of-the-equipments-result">
                      <div className="total-devices-container">
                        <h4>Total no.of equipment</h4>
                        <h3>{totalEquipments}</h3>
                      </div>
                      <div className="total-hours-container">
                        <h4>Total usage of all the equipment</h4>
                        <h3 className="total-usage-hours-label">
                          {totalEquipmentsWithkWh.toLocaleString() + ' '}
                          kW
                        </h3>
                      </div>
                      <div className="unit-cost-container">
                        <h4>Unit cost</h4>
                        <h3>
                          {currencyTypeSymbol +
                            energyCalculatorSummary.energyCost}
                        </h3>
                      </div>
                    </div>
                    <br />
                    <div className="results-data-table-container">
                      <Table className="summary-table">
                        <Table.Header>
                          <Table.HeaderCell content="Weekly" />
                          <Table.HeaderCell content="" />
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <h4>Total equipment running hours</h4>
                            </Table.Cell>
                            <Table.Cell>
                              <h4 className="calculated-hours-label">
                                {DAILY_HOURS * WEEKLY_DAYS} hrs
                              </h4>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <h4>Average hours building is occupied</h4>
                            </Table.Cell>
                            <Table.Cell>
                              <h4 className="calculated-hours-label">
                                {Math.floor(totalWorkingHours)} hrs
                              </h4>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                      <Table className="summary-table">
                        <Table.Header>
                          <Table.HeaderCell content="Monthly" />
                          <Table.HeaderCell content="" />
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <h4>Total equipment running hours</h4>
                            </Table.Cell>
                            <Table.Cell>
                              <h4 className="calculated-hours-label">
                                {DAILY_HOURS * MONTHLY_DAYS} hrs
                              </h4>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <h4>Average hours building is occupied</h4>
                            </Table.Cell>
                            <Table.Cell>
                              <h4 className="calculated-hours-label">
                                {Math.floor(totalWorkingHoursMonthly)} hrs
                              </h4>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                      <Table className="summary-table">
                        <Table.Header>
                          <Table.HeaderCell content="Annually" />
                          <Table.HeaderCell content="" />
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <h4>Total equipment running hours</h4>
                            </Table.Cell>
                            <Table.Cell>
                              <h4 className="calculated-hours-label">
                                {DAILY_HOURS * YEARLY_DAYS} hrs
                              </h4>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <h4>Average hours building is occupied</h4>
                            </Table.Cell>
                            <Table.Cell>
                              <h4 className="calculated-hours-label">
                                {Math.floor(totalWorkingHoursYearly)} hrs
                              </h4>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </div>
                  </div>
                </div>

                <div className="line-break-container"></div>

                <div id="resultsContainer">
                  <h3 className="equipment-summary-title">Estimated Savings</h3>
                  <div className="energy-chart-container">
                    <div
                      className="weekly-chart-container"
                      id="weeklyChartContainer"
                      ref={weeklyChartContainerRef}
                    >
                      <div className="dashboard-container">
                        <h3 className="chart-title">Weekly</h3>
                        <h3 className="chart-result-label">
                          {(
                            parseFloat(outOfHoursWeeklyUse) -
                            parseFloat(afterScheduleWeeklyUse)
                          ).toLocaleString()}{' '}
                          kWh{' '}
                        </h3>
                        <label className="saving-label">Energy Savings</label>
                        <h3 className="chart-result-label">
                          {currencyTypeSymbol +
                            (
                              parseFloat(outOfHoursWeeklyCost) -
                              parseFloat(afterScheduleWeeklyCost)
                            ).toLocaleString()}{' '}
                        </h3>
                        <label className="saving-label">Monetary Savings</label>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Chart width={160} height={300} title="" subtitle="">
                          <Chart.Column
                            name="Current Energy"
                            showTooltips={true}
                            color="#A0D5F4"
                            data={[parseFloat(outOfHoursWeeklyUse)]}
                          />
                          <Chart.Column
                            color="#0071B3"
                            showTooltips={true}
                            name="Energy with Connected Power"
                            data={[parseFloat(afterScheduleWeeklyUse)]}
                          />

                          <Chart.XAxis categories={['Energy (kWH)']} />
                          <Chart.Tooltip valueSuffix=" kWH" />
                        </Chart>

                        <Chart width={160} height={300} title="" subtitle="">
                          <Chart.Column
                            name="Current Cost"
                            color="#C1E68F"
                            showTooltips={true}
                            data={[parseFloat(outOfHoursWeeklyCost)]}
                          />
                          <Chart.Column
                            color="#7EB338"
                            name="Cost with  Connected  Power"
                            showTooltips={true}
                            data={[parseFloat(afterScheduleWeeklyCost)]}
                          />
                          <Chart.XAxis
                            categories={['Cost (' + currencyTypeSymbol + ')']}
                          />
                          <Chart.Tooltip
                            valueSuffix={' ' + currencyTypeSymbol}
                          />
                        </Chart>
                      </div>
                    </div>

                    <div
                      className="monthly-chart-container"
                      id="monthlyChartContainerDiv"
                      ref={monthlyChartContainerRef}
                    >
                      <div className="dashboard-container">
                        <h3 className="chart-title">Monthly</h3>
                        <h2 className="chart-result-label">
                          {(
                            parseFloat(outOfHoursMonthlyUse) -
                            parseFloat(afterScheduleMonthlyUse)
                          ).toLocaleString()}{' '}
                          kWh
                        </h2>
                        <label className="saving-label">Energy Savings</label>
                        <h2 className="chart-result-label">
                          {currencyTypeSymbol +
                            (
                              parseFloat(outOfHoursMonthlyCost) -
                              parseFloat(afterScheduleMonthlyCost)
                            ).toLocaleString()}{' '}
                        </h2>
                        <label className="saving-label">Monetary Savings</label>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Chart width={160} height={300} title="" subtitle="">
                          <Chart.Column
                            name="Current Energy"
                            showTooltips={true}
                            color="#A0D5F4"
                            data={[parseFloat(outOfHoursMonthlyUse)]}
                          />
                          <Chart.Column
                            color="#0071B3"
                            showTooltips={true}
                            name="Energy with Connected Power"
                            data={[parseFloat(afterScheduleMonthlyUse)]}
                          />
                          <Chart.XAxis categories={['Energy (kWH)']} />
                          <Chart.Tooltip valueSuffix=" kWH" />
                        </Chart>

                        <Chart width={160} height={300} title="" subtitle="">
                          <Chart.Column
                            name="Current Cost"
                            color="#C1E68F"
                            showTooltips={true}
                            data={[parseFloat(outOfHoursMonthlyCost)]}
                          />
                          <Chart.Column
                            color="#7EB338"
                            name="Cost with  Connected  Power"
                            showTooltips={true}
                            data={[parseFloat(afterScheduleMonthlyCost)]}
                          />

                          <Chart.XAxis
                            categories={['Cost (' + currencyTypeSymbol + ')']}
                          />
                          <Chart.Tooltip
                            valueSuffix={' ' + currencyTypeSymbol}
                          />
                        </Chart>
                      </div>
                    </div>

                    <div
                      className="annual-chart-container"
                      id="annualChartContainerDiv"
                      ref={annualChartContainerRef}
                    >
                      <div className="dashboard-container">
                        <h3 className="chart-title">Annually</h3>
                        <h2 className="chart-result-label">
                          {(
                            parseFloat(outOfHoursYearlyUse) -
                            parseFloat(afterScheduleYearlyUse)
                          ).toLocaleString()}{' '}
                          kWh
                        </h2>
                        <label className="saving-label">Energy Savings</label>
                        <h2 className="chart-result-label">
                          {currencyTypeSymbol +
                            (
                              parseFloat(outOfHoursYearlyCost) -
                              parseFloat(afterScheduleYearlyCost)
                            ).toLocaleString()}{' '}
                        </h2>
                        <label className="saving-label">Monetary Savings</label>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Chart width={160} height={300} title="" subtitle="">
                          <Chart.Column
                            name="Current Energy"
                            showTooltips={true}
                            color="#A0D5F4"
                            data={[parseFloat(outOfHoursYearlyUse)]}
                          />
                          <Chart.Column
                            color="#0071B3"
                            showTooltips={true}
                            name="Energy with Connected Power"
                            data={[parseFloat(afterScheduleYearlyUse)]}
                          />
                          <Chart.XAxis categories={['Energy (kWH)']} />
                          <Chart.Tooltip valueSuffix=" kWH" />
                        </Chart>

                        <Chart width={160} height={300} title="" subtitle="">
                          <Chart.Column
                            name="Current Cost"
                            color="#C1E68F"
                            showTooltips={true}
                            data={[parseFloat(outOfHoursYearlyCost)]}
                          />
                          <Chart.Column
                            color="#7EB338"
                            name="Cost with Connected Power"
                            showTooltips={true}
                            data={[parseFloat(afterScheduleYearlyCost)]}
                          />
                          <Chart.XAxis
                            categories={['Cost (' + currencyTypeSymbol + ')']}
                          />
                          <Chart.Tooltip
                            valueSuffix={' ' + currencyTypeSymbol}
                          />
                        </Chart>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="send-results-container">
                  <div className="actions-container">
                    <Button
                      type="secondary"
                      size="medium"
                      content="Download Your Plug Load Energy Report"
                      className="button-style-add"
                      onClick={exportToPDF}
                    />
                  </div>
                  <div className="line-break-container"></div>
                  <div className="acknowledgement-container">
                    <p className="acknowledgement-text">
                      *Power usage estimates were derived from the U.S.
                      Environmental Protection Agency's and U.S. Department of
                      Energy's Energy Star® website as of October 2024 available
                      at:{' '}
                      <a
                        href="https://www.energystar.gov/"
                        className="energy-star-link"
                      >
                        ENERGY STAR
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal
          className="popup-theme-wrap marketo-form-popup"
          onClose={() => setToggleMarketoModal(false)}
          closeIcon={true}
          size="large"
          open={toggleMarketoModal}
        >
          <Modal.Content>
            {isLoadingForm === true ? (
              <Loader text="Loading" overlayOpacity={0.2} />
            ) : (
              <h3>You are a step away...</h3>
            )}
            <Form
              {...inputs}
              onFormSubmit={handleFormSubmit}
              onFormSuccess={handleFormSuccess}
              onFormLoad={handleFormLoad}
            />
          </Modal.Content>
        </Modal>

        <Modal
          className="popup-theme-wrap"
          onClose={() => setToggleResultResponseModal(false)}
          closeIcon={true}
          size="mini"
          open={toggleResultResponseModal}
        >
          <Modal.Content>
            <span>Please add equipment details to generate report.</span>
          </Modal.Content>
          <Modal.Footer>
            <Button
              type="primary"
              size="small"
              onClick={() => setToggleResultResponseModal(false)}
              content="OK"
            />
          </Modal.Footer>
        </Modal>
      </div>
    </main>
  );
}

export default EnergyCalculator;
